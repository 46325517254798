var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("q-table", {
            attrs: {
              grid: "",
              title: "",
              data: _vm.gridline.data,
              "hide-header": "",
              "hide-bottom": "",
              "rows-per-page-options": [0],
              "virtual-scroll": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "q-card",
                          { staticClass: "mobileTableCardLayer" },
                          [
                            _c(
                              "q-card-section",
                              {
                                staticClass: "bg-grey-2",
                                attrs: { horizontal: "" },
                              },
                              [
                                _c(
                                  "q-card-section",
                                  { staticClass: "q-pt-xs" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-weight-bold",
                                            class: _vm.getLineKindColor(
                                              props.row.approvalKindCd
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(props.row.approvalKindName)
                                            ),
                                          ]
                                        ),
                                        _vm._v("  - "),
                                        _c("span", [
                                          _vm._v(
                                            "[" +
                                              _vm._s(props.row.deptName) +
                                              "] " +
                                              _vm._s(
                                                _vm.replaceBrText(
                                                  props.row.approvalUserName
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-card-section",
                              { staticClass: "grid-card-etc" },
                              [
                                _c("div", { staticClass: "text-grid-etc" }, [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.getLineKindColor2(
                                        props.row.approvalStatusName
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(props.row.approvalStatusName)
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "text-grid-etc" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(props.row.approvalDt)),
                                  ]),
                                ]),
                                _c("br"),
                                props.row.returnReason
                                  ? _c(
                                      "div",
                                      { staticClass: "text-grid-etc-title" },
                                      [_vm._v(" 반려사유 : ")]
                                    )
                                  : _vm._e(),
                                props.row.returnReason
                                  ? _c(
                                      "div",
                                      { staticClass: "text-grid-etc" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(props.row.returnReason)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "apprline2",
            attrs: {
              title: "회람",
              tableId: "apprline2",
              topBorderClass: "topcolor-orange",
              columnSetting: false,
              isFullScreen: false,
              isTitle: true,
              hideBottom: true,
              hideHeader: true,
              usePaging: false,
              filtering: false,
              gridHeightAuto: true,
              columns: _vm.gridline2.columns,
              data: _vm.gridline2.data,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }